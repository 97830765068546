import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SocketIODispatcher from "./SocketIODispatcher";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { SocketContext, socket } from "./SocketIOContext";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <SocketContext.Provider value={socket}>
        <SocketIODispatcher />
        <Switch>
          <Route path={`${APP_PREFIX_PATH}/Staff`} component={lazy(() => import(`./Staff`))} />
          <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
          <Route path={`${APP_PREFIX_PATH}/schedule`} component={lazy(() => import(`./schedule`))} />
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
        </Switch>
      </SocketContext.Provider>
    </Suspense>
  );
};

export default React.memo(AppViews);
