import React from "react";
import { notification } from "antd";
import { SocketContext } from "./SocketIOContext";
import { useAuth } from "../../services/Auth";

const SocketIODispatcher = () => {
  const { auth } = useAuth();
  const socket = React.useContext(SocketContext);

  socket.on("connection", (socket) => {
    console.log("connected", socket.id);
  });
  socket.on("MessageBar", (data) => {
 
    if (data.exclude !== undefined && data.exclude === auth.data._id) return;

    // Show notification
    notification[data.type]({
      message: data.message || undefined,
      description: data.description || undefined,
      duration: data.duration || 5,
      placement: "bottomLeft",
    });
  });

  return <></>;
};

export default SocketIODispatcher;
