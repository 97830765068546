import {
  DatabaseOutlined,
  DashboardOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "base",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.base",
    icon: DatabaseOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/home`,
        title: "sidenav.dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },

      //************************************************************************* */

      //************************************************************************* */
      {
        key: "Staff",
        path: `${APP_PREFIX_PATH}/Staff/list`,
        allowed_paths: [{ path: `${APP_PREFIX_PATH}/manageStaff/edit/.*`, title: "sidenav.staff.list" }],
        title: "sidenav.staff.list",
        icon: ReconciliationOutlined,
        breadcrumb: true,
        submenu: [],
        opened: false,
      },
      {
        key: "schedule",
        path: `${APP_PREFIX_PATH}/schedule`,
        allowed_paths: [{ path: `${APP_PREFIX_PATH}/schedule`, title: "sidenav.schedule" }],
        title: "sidenav.schedule",
        icon: ReconciliationOutlined,
        breadcrumb: false,
        submenu: [],
        opened: false,
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
