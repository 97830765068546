import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> - Feito com ❤️ e ☕ - versão 0.0.466 -{" "}
      </span>
    </footer>
  );
}
